/*
 * @Author: 肖槿
 * @Date: 2021-02-25 13:42:23
 * @Description: websocket类
 * @LastEditors: 肖槿
 * @LastEditTime: 2021-02-25 16:03:44
 * @FilePath: \liver-dashbord\src\utils\ws.js
 */

import WebSocket from "websocket";
let WebSocketClient = WebSocket.w3cwebsocket;
class WS {
    constructor(socketUrl, fn) {
        this.socketUrl = socketUrl
        this.lockReconnect = false
        this.maxReconnectNum = 0
        this.fn = fn
        this.data = null
        const _this = this
        this.heartCheck = {
            timeout: 3000,
            timeoutObj: null,
            serverTimeroutObj: null,
            reset() {
                clearTimeout(this.timeoutObj)
                return this
            },
            start() {
                console.log('开始检查')
                this.timeoutObj = setTimeout(() => {
                    // 心跳时间内收不到消息，主动触发连接关闭，开始重连
                    const wsinstance = _this.getInstance()
                    wsinstance.close();
                }, this.timeout)
            }
        }
        try {
            this.socketClient = new WebSocketClient(this.socketUrl);
            this.startWebsocket()
        } catch (error) {
            console.error('初始ws失败:', error)
            this.reconnectWebsocket(this.socketUrl)
        }
    }
    getInstance() {
        return this.socketClient
    }
    startWebsocket() {
        this.socketClient.onerror = this.websocketError.bind(this);
        this.socketClient.onopen = this.websocketOpened.bind(this);
        this.socketClient.onclose = this.websocketClosed.bind(this);
        this.socketClient.onmessage = this.receivedMessage.bind(this);
    }
    websocketClosed() {
        console.log('链接关闭')
        this.reconnectWebsocket(this.socketUrl)
    }
    websocketError(err) {
        console.error('ws发生异常：', err)
        this.reconnectWebsocket(this.socketUrl)
    }
    websocketOpened() {
        console.log('ws链接')
        //心跳检测重置\
        this.heartCheck.reset().start();
    }
    reconnectWebsocket() {
        timer && clearTimeout(timer)
        // this.maxReconnectNum >= 10
        if (this.maxReconnectNum >= 10 || this.lockReconnect) return;
        this.lockReconnect = true
        var timer = setTimeout(() => {
            this.socketClient = new WebSocketClient(this.socketUrl);
            this.startWebsocket()
            this.maxReconnectNum++;
            this.lockReconnect  = false
        }, 3000)
    }
    receivedMessage(data) {
        this.heartCheck.reset().start();
        this.data = data
        this.fn(data)
    }
}

export default WS