<!--
 * @Author: 肖槿
 * @Date: 2021-02-23 17:24:05
 * @Description: 
 * @LastEditors: 肖槿
 * @LastEditTime: 2021-04-20 17:23:14
 * @FilePath: \gm-taobao-dashbord\src\App.vue
-->
<template>
  <!--  socket {{socketUrl}} {{socketState}}-->
  <div class="container">
    <div class="left">
      <div class="item" v-if="item.title">
        <div class="talk-num">
          <b>{{item.talk_no}}</b>
          <span>号</span>
        </div>
        <div class="cover">
          <img :src="item.cover" />
        </div>
        <div class="item_label">
          <div class="title">{{item.title}}</div>
          <div class="price">￥{{item.price / 100}}</div>
        </div>
      </div>
      <div class="item empty" v-else>
        <img src="@/assets/dashbord-2.png.png" alt />
        <span>当前没有正在讲解中的商品~</span>
      </div>
      <div class="item_selling_points" v-if="item.sellingPoints.length">
        <div v-for="selling_point in item.sellingPoints" :key="selling_point" class="point">
          <div class="circle" v-show="selling_point.point"></div>
          {{selling_point.point}}
        </div>
      </div>
      <div class="item_selling_points empty" v-else>
        <img src="@/assets/dashbord-3.png.png" alt />
        <span>没有商品卖点提示~</span>
      </div>
      <div class="chat_message" ref="listCon" v-if="chatMessages.length !== 0">
        <div v-for="chatMessage in chatMessages" :key="chatMessage">
          <div class="message">
            <div class="user">{{chatMessage.userName}}：</div>
            <div class="label">{{chatMessage.message}}</div>
          </div>
        </div>
      </div>
      <div class="chat_message empty" ref="listCon" v-else>
        <img src="@/assets/dashbord-1.png" alt />
        <span>粉丝正在酝酿问题~</span>
      </div>
    </div>
    <div class="right">
      <div class="realtime_message">
        <!-- <div class="title">
          <span class="point"></span>实时消息
        </div>-->
        <div class="label thirty-second" v-for="item in realtimeMessage" :key="item">{{item}}</div>
        <div class="label empty" ref="empty">
          <img src="@/assets/dashbord-1.png" alt />
          <span>没有实时提示哦~</span>
        </div>
      </div>

      <div class="fixed_message">
        <div class="title">
          <span class="point"></span>固定消息
        </div>
        <div v-if="fixedMessage" class="label" v-html="fixedMessage"></div>
        <div class="label empty" v-else>
          <img src="@/assets/dashbord-1.png" alt />
          <span>没有设置固定提示哦~</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MESSAGE_TYPE } from "/const/message_type";
import WS from "@/utils/ws";
import { HOST } from "/const/api";
import WebSocket from "websocket";

export default {
  name: "App",
  components: {},
  data() {
    return {
      socketState: "未连接",
      socket: null,
      socketUrl: null,
      item: {
        title: null,
        cover: null,
        itemId: null,
        sellingPoints: []
      },
      realMessages: [],
      chatMessages: [],
      realtimeMessage: [],
      fixedMessage: null
    };
  },
  methods: {
    getWebsocketKeyFrom() {
      let path = location.pathname;
      if (path == "/") return null;
      let websocketKey = path.substring(1);
      return websocketKey;
    },
    receivedMessage(e) {
      if (typeof e.data === "string") {
        // CANCEL_EXPLAIN
        let message = JSON.parse(e.data);
        switch (message.msgType) {
          case MESSAGE_TYPE.ITEM_MESSAGE:
            this.changeItemData(message.content);
            break;
          case MESSAGE_TYPE.CHAT_MESSAGE:
            this.changeChatMessage(message.content);
            break;
          case MESSAGE_TYPE.FIXED_MESSAGE:
            this.changeFixedMessage(message.content);
            break;
          case MESSAGE_TYPE.REALTIME_MESSAGE:
            this.changeRealtimeMessage(message.content);
            break;
          case MESSAGE_TYPE.CANCEL_EXPLAIN:
            this.item = {
              title: null,
              cover: null,
              itemId: null,
              sellingPoints: []
            };
            break;
          default:
            break;
        }
      }
      this.$refs.listCon.scrollTop = 99999;
    },

    changeItemData(content) {
      this.item.title = content.title;
      this.item.cover = content.cover;
      this.item.talk_no = content.talk_no;
      this.item.price = content.price;
      this.item.itemId = "货号: " + content.item_id;
      this.item.sellingPoints = content.selling_points
        ? content.selling_points.filter(v => v.point)
        : [];
    },
    changeChatMessage(content) {
      let messages = this.realMessages;
      messages.unshift({
        userName: content.publisherNick,
        message: content.content
      });
      this.chatMessages = messages.slice(0, 8).reverse();
    },
    changeFixedMessage(content) {
      this.fixedMessage = content;
    },
    changeRealtimeMessage(content) {
      this.$refs.empty.className = 'label empty'
      this.realtimeMessage[0] = content.replace(/\s/gi, "");
      this.$nextTick(() => {
        this.$refs.empty.className = 'label empty secondThirty'
      })
    }
  },
  mounted() {
    let websocketKey = this.getWebsocketKeyFrom();
    this.socketUrl = "wss://" + HOST + "/ws/" + websocketKey;
    this.ws = new WS(this.socketUrl, this.receivedMessage);
  }
};
</script>

<style lang="scss" scoped>
.container {
  background: #111;
  display: flex;
  height: 100vh;

  .thirty-second {
    animation: thirtySecond 1s 30s forwards;
    position: absolute;
  }

  .left {
    flex: 1;
    padding: 1.2rem 0.6rem 1.2rem 1.2rem;
    .item {
      background: linear-gradient(225deg, #2a3038 0%, #262b32 100%);
      border-radius: 0.2rem;
      padding: 1.2rem;
      display: flex;
      height: 8rem;

      .cover {
        img {
          border-radius: 0.2rem;
          width: 5.6rem;
          height: 5.6rem;
        }
      }
      & .talk-num {
        color: #fff;
        line-height: 5.6rem;
        margin-right: 0.6rem;
        width: 2.6rem;
        & b {
          font-size: 1.6rem;
          font-weight: 400;
        }
        & span {
          font-size: 0.7rem;
        }
      }
      .item_label {
        width: 34.5rem;
        margin-left: 0.8rem;
        font-size: 1.5rem;
        // display: flex;
        align-items: center;
        justify-content: center;

        .title {
          color: #fff;
          line-height: 1.4;
          & span {
            color: #ff7b00;
          }
        }
        .price {
          font-size: 1.4rem;
          font-weight: 500;
          color: #ff2851;
          line-height: 1.6rem;
        }
        .item_id {
          background: #6647ff;
          border-radius: 1rem;
          font-size: 1.2rem;
          color: #fff;
          padding: 0.35rem 0.8rem;
          align-self: flex-end;
        }
      }
    }

    .item_selling_points {
      background: #1e1e1e;
      border-radius: 0.2rem;
      margin-top: 1.2rem;
      padding: 1.2rem;
      height: 10rem;
      color: #fff;

      .point {
        display: inline-block;
        width: 50%;
        line-height: 2;
        font-size: 1.3rem;

        .circle {
          display: inline-block;
          border-radius: 50%;
          width: 0.5rem;
          height: 0.5rem;
          background: #ff7b00;
          margin: 0 0.4rem;
        }
      }
    }

    .chat_message {
      background: #1e1e1e !important;
      border-radius: 0.2rem;
      margin-top: 1.2rem;
      padding: 1.2rem;
      height: calc(100vh - 22.5rem);
      overflow: hidden;

      .message {
        color: white;
        line-height: 1.5rem;
        font-size: 1.2rem;
        background: rgba(255,255,255,.12);
        margin-bottom: 0.6rem;
        border-radius: 1.1rem;
        padding: 0.35rem 0.9rem;
        display: flex;
        .user {
          color: #ff7b00;
          white-space: nowrap;
        }
        .label {
          color: white;
          flex: 1;
        }
      }
    }
  }
  .right {
    flex: 1;
    padding: 1.2rem 1.2rem 1.2rem 0.6rem;

    .realtime_message {
      background: #1e1e1e;
      border-radius: 0.2rem;
      padding: 1.2rem;
      height: 8rem;
      color: #fff;
      position: relative;

      & .label {
        font-size: 2.2rem;
        color: #fff;
        line-height: 3.3rem;
        margin-top: 1rem;
      }
    }
    .fixed_message {
      background: #1e1e1e;
      border-radius: 0.2rem;
      margin-top: 1.2rem;
      padding: 1.2rem;
      height: calc(100vh - 11.4rem);
      color: #fff;
      overflow: hidden;
      position: relative;
      & * {
        line-height: 1.5;
      }
      & .label {
        margin-top: 1rem;
        font-size: 2.2rem;
      }
    }
    .realtime_message,
    .fixed_message {
      .title {
        display: inline-block;
        font-size: 1rem;
        color: #ff2851;
        line-height: 1.5rem;
        width: 6.5rem;
        height: 1.5rem;
        background: #2d2d2d;
        border-radius: 0.2rem 0px 0.8rem 0px;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;

        & .point {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background: #ff2851;
          display: inline-block;
          margin-right: 0.4rem;
        }
      }
    }
  }
  .realtime_message .label.empty{
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    margin:0 auto;
    &.secondThirty{
      animation: thirtySecond2 1s 30s backwards;
    }
  }
  .item.empty,
  .item_selling_points.empty,
  .chat_message.empty,
  .realtime_message .label.empty,
  .fixed_message .label.empty,
  .item.empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & span {
      color: #686b7b;
      font-weight: bold;
      line-height: 1;
      font-size: 0.9rem;
    }
    & img {
      margin-bottom: 1rem;
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .fixed_message .label.empty {
    height: 100%;
  }
}
</style>
<style lang="scss">
@keyframes thirtySecond {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes thirtySecond2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>