/*
 * @Author: 肖槿
 * @Date: 2021-02-25 10:18:57
 * @Description: 
 * @LastEditors: 肖槿
 * @LastEditTime: 2021-03-02 14:28:32
 * @FilePath: \liver-dashbord\const\api.js
 */

export const HOST = process.env.VUE_APP_HOST

