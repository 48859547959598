/*
 * @Author: 肖槿
 * @Date: 2021-04-13 13:36:33
 * @Description: 
 * @LastEditors: 肖槿
 * @LastEditTime: 2021-04-13 13:59:49
 * @FilePath: \gm-taobao-dashbord\const\message_type.js
 */

const MESSAGE_TYPE = {
    DEFAULT: 0,
    FIXED_MESSAGE: 1,
    REALTIME_MESSAGE: 2,
    ITEM_MESSAGE: 3,
    CHAT_MESSAGE: 4,
    CANCEL_EXPLAIN: 14
}
export {MESSAGE_TYPE}