/*
 * @Author: 肖槿
 * @Date: 2021-02-23 17:24:05
 * @Description: 
 * @LastEditors: 肖槿
 * @LastEditTime: 2021-02-23 17:38:56
 * @FilePath: \liver-dashbord\src\main.js
 */
import { createApp } from 'vue'
import App from './App.vue'
import './utils/rem'
import './assets/reset.css'


createApp(App).mount('#app')
